<template>
  <!-- 人才-个人中心总页面 -->
  <div>
    <talentNose></talentNose>
    <router-view></router-view>
    <pithyTail></pithyTail>
  </div>
</template>
<script>
import talentNose from "@/components/talentNose/talentNose";
import pithyTail from "@/components/pithyTail/pithyTail";
export default {
  name: "talentPage",
  components: {
    talentNose,
    pithyTail,
  },
};
</script>
