<template>
  <!-- 人才个人中心--头部 -->
  <div>
    <div class="userInfoNav">
      <span class="lander">
        <span>{{ userInfo.u_phone }}</span>
        <span> | </span>
        <span @click="goLogin">退出登录</span>
      </span>
    </div>
    <div class="nosebox">
      <section style="position:relative">
        <a class="logo" @click="goHome">
          <img :src="logo" alt="logo" />
        </a>
        <ul class="menu-list">
          <li>
            <!-- <router-link :to="{ path: '/talentPage/index' }">
              个人中心
            </router-link> -->
            <a @click="goPath('/talentPage/index')">
              个人中心
            </a>
          </li>
          <li class="li-ulList">
            <a>
              简历管理
            </a>
            <ul class="li-menu">
              <li>
                <a @click="goPath('/talentPage/talentResume')">
                  我的简历
                </a>
              </li>
              <li>
                <a @click="goPath('/talentPage/sendRecord')">
                  投递记录
                </a>
              </li>
              <!-- <li>
                <a @click="goPath('/talentPage/downRecord')">
                  下载记录
                </a>
              </li> -->
            </ul>
          </li>
          <li>
            <a @click="goPath('/talentPage/postStar')">
              职位收藏
            </a>
            <!-- <router-link :to="{ path: '/talentPage/postStar' }">
              职位收藏
            </router-link> -->
          </li>
          <!-- <li>
            <router-link :to="{ path: '/talentPage/talentResume' }">
              顾问服务
            </router-link>
          </li> -->
          <li class="li-ulList">
            <a>
              账号安全
            </a>
            <ul class="li-menu">
              <li>
                <a @click="goPath('/talentPage/editPassword')">
                  修改密码
                </a>
              </li>
              <li>
                <a @click="goPath('/talentPage/editPhone')">
                  修改手机
                </a>
              </li>
            </ul>
          </li>
          <li>
            <!-- <router-link :to="{ path: '/talentPage/realName' }">
              实名认证
            </router-link> -->
            <a @click="goPath('/talentPage/realName')">
              实名认证
            </a>
          </li>
          <li>
            <!-- <router-link :to="{ path: '/talentPage/message' }">
              消息管理
            </router-link> -->
            <a>
              消息管理
            </a>
            <ul class="li-menu" style="width: 85px;">
              <li>
                <a @click="goPath('/talentPage/message')">
                  系统消息
                </a>
              </li>
              <li>
                <a @click="goPath('/talentPage/talfeeback')">
                  我的反馈
                </a>
              </li>
            </ul>
          </li>
          <!-- <li>
            <router-link :to="{ path: '/talentPage/talentResume' }">
              意见反馈
            </router-link>
          </li> -->
          <li>
            <!-- <router-link :to="{ path: '/talentPage/weChatBind' }">
              微信绑定
            </router-link> -->
            <a @click="goPath('/talentPage/weChatBind')">
              微信绑定
            </a>
          </li>
        </ul>
        <div class="comMark"></div>
      </section>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import $ from "jquery";
export default {
  name: "nose",
  data() {
    return {
      logo: require("@/assets/img/logo.png"),
    };
  },
  computed: {
    ...mapState(["userInfo"]),
    userInfo() {
      let k = this.$store.state.userInfo;
      if (JSON.stringify(k) == "{}") {
        return {};
      }
      return JSON.parse(localStorage.getItem("userInfo"));
    },
  },
  methods: {
    ...mapMutations([
      "changetalentInfo",
      "changetalentCer",
      "changetalentResume",
    ]),
    goLogin() {
      localStorage.removeItem("Authorization");
      this.$router.push({ path: "/Login" });
    },
    goHome() {
      this.$router.push({ path: "/Home/index" });
    },
    hoverMenu() {
      $(".li-ulList").hover(
        function() {
          $(this)
            .children(".li-menu")
            .show();
        },
        function() {
          $(this)
            .children(".li-menu")
            .hide();
        }
      );
    },
    goPath(src) {
      let routerUrl = this.$route.path;
      console.log(routerUrl);
      if (
        routerUrl == "/talentpage/firstResume" ||
        routerUrl == "/talentPage/firstresume"
      ) {
        this.$message.warning("您需要发布简历，才可进行后续操作！");
        return;
      }
      this.$router.push({ path: src });
      $(".li-menu").hide();
    },
    gettalentInfo() {
      this.$http.post("talent/index/baseinfo").then((res) => {
        if (res.data.resultCode === 1) {
          if (res.data.resultData.base.length > 0) {
            this.changetalentInfo({
              talentInfo: res.data.resultData.base[0],
            });
          }
          this.changetalentCer({
            talentCer: res.data.resultData.cer,
          });
          if (res.data.resultData.resume.length > 0) {
            this.changetalentResume({
              talentResume: res.data.resultData.resume[0],
            });
          }
        }
      });
    },
  },
  mounted() {
    this.hoverMenu();
    this.gettalentInfo();
  },
};
</script>
<style scoped>
.userInfoNav {
  text-align: right;
  width: 1200px;
  margin: 0 auto;
  padding: 10px 20px;
}
.nosebox {
  min-width: 1240px;
  background-color: #131d28;
}
section .logo {
  margin-top: 5px;
}
section {
  height: 80px;
  display: flex;
  align-items: center;
  background-color: #131d28;
}
.menu-list {
  display: inline-block;
  list-style: none;
  margin-left: auto;
}
.menu-list > li {
  display: block;
  float: left;
  color: #fff;
  cursor: pointer;
  padding: 10px 0;
}
.menu-list li a {
  color: #fff;
  padding: 10px 16px;
}
.menu-list li:last-child a {
  padding-right: 0;
}
.menu-list > li:hover > a {
  /* opacity: 0.6; */
  /* background-color: #192c40; */
  color: #b1b6ca;
}
.menu-list li:last-child {
  padding-right: 0px;
}
.li-ulList {
  position: relative;
}
.li-menu {
  display: none;
  position: absolute;
  top: 38px;
  padding: 6px 0;
  background: #131d28;
  list-style: none;
  z-index: 10;
}
.li-menu li a {
  display: inline-block;
  padding: 10px 16px;
}
.li-menu li a:hover {
  color: #b1b6ca;
}
.loginBtn span,
.lander span {
  cursor: pointer;
}
.lander {
  margin-left: auto;
  color: #333;
}
</style>
